import React, { useEffect, useState, useSyncExternalStore } from 'react';
import i18n from 'i18next';
import axios from 'axios';
import sdk from '@hubportal/sdk';
import { initReactI18next } from 'react-i18next';
import { tokenMiddleware } from 'utils/network/tokenMiddleware';
import { config } from '../../i18n';
import RidersParcel from './index';

// TODO: find a way to clean up this tailwind directive
import '../../tailwind.css';
import '@hubportal/components/index.css';
import EppoProvider from 'utils/eppo/provider';

i18n.use(initReactI18next).init(config);

export default function Root({
  authClient,
  mapEnabled = true,
  titleEnabled = true,
  isLiveSteering = false,
}: PropsWithAuth0Client & {
  mapEnabled: boolean;
  titleEnabled: boolean;
  isLiveSteering: boolean;
}): JSX.Element {
  const [loading, setLoading] = useState(true);

  const hub = useSyncExternalStore(sdk.onHubChange, () => sdk.getHub()?.slug);

  const locale = useSyncExternalStore(sdk.onLocaleChange, () =>
    sdk.getLocale()
  );

  const country = useSyncExternalStore(sdk.onCountryChange, () =>
    sdk.getCountry()
  );

  useEffect(() => {
    locale && typeof locale === 'string' && i18n.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    axios.interceptors.request.use(tokenMiddleware(authClient));
    setLoading(false);
  }, []);

  if (loading) {
    return <div>...</div>;
  }

  return (
    <React.StrictMode>
      <EppoProvider>
        <div
          style={{
            flex: 1,
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
          className="bg-primary"
        >
          <RidersParcel
            hub={hub}
            country={country}
            mapEnabled={mapEnabled}
            titleEnabled={titleEnabled}
            isLiveSteering={isLiveSteering}
          />
        </div>
      </EppoProvider>
    </React.StrictMode>
  );
}
