export const CountByStatus = ({
  count,
  status,
}: {
  count: number;
  status: string;
}): JSX.Element => {
  return (
    <div className="flex flex-col gap-2 text-center min-w-0 break-words">
      <div className="text-white title-m">{count}</div>
      <div className="text-gray-300 text-xs">{status}</div>
    </div>
  );
};
