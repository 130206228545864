import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import singleSpaCss from 'single-spa-css';
import sdk from '@hubportal/sdk';

import Root from './root';

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: process.env.ENVIRONMENT !== 'local',
  shouldUnmount: true,
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err: Error, info: React.ErrorInfo) {
    sdk.reportError(err, '@hubportal/riders-overview', info);
    return <></>;
  },
});

export const name = 'riders-parcel';

export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];

export const mount = [cssLifecycles.mount, reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
