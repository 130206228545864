/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { delay } from 'utils/helpers';

const DEFAULT_INTERVAL = 7000;

// if it runs out of retries, it  will resolve to an rejected promise, containing the latest error
function retryFn(fn, retries = 2, timeoutBetweenAttempts = DEFAULT_INTERVAL) {
  return Promise.resolve()
    .then(fn)
    .catch(function retry(err) {
      if (retries-- > 0)
        return delay(timeoutBetweenAttempts).then(fn).catch(retry);
      throw err;
    });
}

function usePolling(
  asyncCallback,
  dependencies = [] as any[],
  { interval = DEFAULT_INTERVAL, retry = 2, onCleanUp = () => {} } = {}
) {
  const timeoutIdRef: React.MutableRefObject<any> = useRef(null);
  const [dead, kill] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (dead) {
      return;
    }
    let _stopped = false;
    (async function pollingCallback() {
      try {
        await retryFn(asyncCallback, retry, interval);
      } catch (err: any) {
        setError(err?.response?.data || err?.message);
        kill(true);
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current =
          !_stopped && setTimeout(pollingCallback, interval);
      }
    })();
    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef?.current);
      onCleanUp();
    };
  }, [...dependencies, interval, dead]);

  return {
    error,
    kill: () => kill(true),
    respawn: () => {
      setError('');
      kill(false);
    },
  };
}

export default usePolling;
