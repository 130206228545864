import { memo, useEffect, useState } from 'react';

interface TimerProps {
  from: number; // timestamp in seconds
}

const format = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = String(Math.floor((seconds % 3600) / 60));
  const s = String(Math.round(seconds % 60));

  return [h, h ? m.padStart(2, '0') : m, s.padStart(2, '0')]
    .filter(Boolean)
    .join(':');
};

const TimerFC = ({ from }: TimerProps): JSX.Element => {
  // different as seconds
  const [diff, setDiff] = useState<number>(
    Math.floor(Date.now() / 1000 - from)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDiff((prevDiff) => prevDiff + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setDiff(Math.floor(Date.now() / 1000 - from));
  }, [from]);

  return <div className="detail-l text-white">{format(diff)}</div>;
};

const Timer = memo(TimerFC);
export { Timer };
