import { Modal, ModalSize, ModalVariant } from '@hubportal/components';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getRiderStateColor,
  getRiderStateLabel,
  RiderWithStatus,
} from 'utils/constants';
import useKeyPress from 'utils/hooks/useKeyPress';
import useOutsideClick from 'utils/hooks/useOutsideClick';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  riders: RiderWithStatus[];
  title: string;
};

export const RidersModal: FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
  riders,
  title,
}: Props) => {
  const { t } = useTranslation();

  const ref = useRef(null);
  const escape = useKeyPress('Escape');

  useEffect(() => {
    // disable background scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = '';
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (escape) {
      onCancel();
    }
  }, [escape, onCancel]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onCancel();
    }
  });

  return (
    <Modal
      open={isOpen}
      variant={ModalVariant.secondary}
      size={ModalSize.small}
      footer={
        <button
          type="submit"
          className="min-w-40 w-auto rounded bg-flinkPink py-2 px-4 text-center text-sm font-semibold disabled:opacity-70"
          onClick={onConfirm}
        >
          Close
        </button>
      }
    >
      <div ref={ref} className="z-50 px-6 pb-6">
        <div className="mb-3 text-left">
          <h2 className="mb-6 text-center font-flink text-lg">{title}</h2>
          <div className="relative rounded border border-[#2A2F32] bg-[#2A2F32] border-opacity-8 bg-opacity-40 p-6">
            <div className="mt-1 h-72 overflow-auto">
              <ul className="w-full">
                {riders.map((rider) => {
                  return (
                    <li
                      key={rider.id}
                      className="flex items-center justify-between p-4"
                    >
                      <div>{`${rider.firstName} ${rider.lastName}`}</div>
                      <div className="flex items-center text-sm text-flinkGray-light">
                        <div
                          className={`mr-2 h-2 w-2 rounded ${getRiderStateColor(
                            rider.status,
                            rider.statusReason
                          )}`}
                        />
                        {t(
                          getRiderStateLabel(rider.status, rider.statusReason)
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
