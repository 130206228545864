import { Icon } from '@hubportal/components';
import React from 'react';

export const WarningBar: React.FC<{
  labels: { title: string; details: string };
  onClickDetails: () => void;
}> = ({ labels, onClickDetails }) => {
  return (
    <div className="px-3 py-2 text-sm bg-[#240412] flex grid-flow-row justify-between items-center rounded">
      <Icon type="caution" size="large" />
      <div className="flex items-center justify-between text-white w-56">
        {labels.title}
      </div>
      <div className="cursor-pointer font-medium" onClick={onClickDetails}>
        {labels.details}
      </div>
    </div>
  );
};
