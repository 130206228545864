import classnames from 'classnames';
import { Icon, IconType } from '@hubportal/components';
import { Timer } from 'components/timer';
import { RiderStates } from 'utils/constants';
import { Employee } from 'models';
import { isVehicleAssignmentEnabled } from 'utils/eppo/helpers';

type Props = {
  rider: Employee;
  underageFlagVisible?: boolean;
};

export const RiderTile = ({
  rider,
  underageFlagVisible,
}: Props): JSX.Element => {
  return (
    <div
      className={`pr-10 py-1 hover:bg-secondary relative flex items-center justify-between cursor-pointer text-white ${
        !isVehicleAssignmentEnabled() ? 'pl-4 mb-2' : 'mb-3'
      }`}
      onClick={() => {
        window.open(`/workforce/${rider.workforceID}`, '_blank');
      }}
    >
      <div className="flex items-center">
        {isVehicleAssignmentEnabled() && (
          <div
            className={`mr-2.5 relative inline-block ${rider.getVehicleIconColor()}`}
          >
            <div className="pr-1 pt-1.5">
              <Icon type={rider.getVehicleIcon() as IconType} size="xlarge" />
              {rider.vehicleType && (
                <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs text-gray-300 bg-[#3A4045] rounded-full">
                  {rider.VehicleNumberOfSContainers}
                </span>
              )}
            </div>
          </div>
        )}

        <div className="leading-none">
          <div className="text-s">
            {rider.getAbrvName()}
            {underageFlagVisible && <span className="text-xs ml-2">⏰</span>}
          </div>
          {rider.vehicleSKU && (
            <span className="text-xs text-gray-300">{rider.vehicleSKU}</span>
          )}
          <div className="flex items-center">
            <span
              className={classnames(
                'w-2 h-2 rounded mr-2',
                rider.getStateColor()
              )}
            />
            <span className="text-xs text-gray-300">
              {rider.getStateMizimized()}
            </span>
          </div>
        </div>
      </div>
      {rider.statusUpdatedAt &&
        [
          RiderStates.ONLINE,
          RiderStates.BUSY,
          RiderStates.RETURNING,
          RiderStates.ON_BREAK,
          RiderStates.TEMP_OFFLINE,
        ].includes(rider.status as RiderStates) && (
          <Timer from={new Date(rider.statusUpdatedAt).getTime() / 1000} />
        )}
      <div className="absolute top-0 right-2 bottom-0 my-auto flex items-center">
        <Icon type="arrowSignRight" />
      </div>
    </div>
  );
};
